<template>
    <div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 广告审核
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
        <el-tabs v-model="type" type="card" @tab-click="tabClick">
            <el-tab-pane label="待审核" name="0">
                <item :activeType="type" type="0"/>
            </el-tab-pane>
            <el-tab-pane label="审核通过" name="1">
                <item :activeType="type" type="1"/>
            </el-tab-pane>
            <el-tab-pane label="审核不通过" name="2">
                <item :activeType="type" type="2"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import item from '@/components/advertising/sloganItem'
export default {
    name: 'review',
    components: {
        item
    },
    data() {
        return {
            type: '0', 
        };
    },
    created() {},
    methods: {
        tabClick(e) {
            //切换type
            this.type = e.props.name
        }
    }
}
</script>
<style scoped>

</style>
